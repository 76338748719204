import React from "react"
import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

const HexagonsV2: React.FC<GatsbyProps> = ({ location }) => {
  return (
    <StandardLayout location={location}>
      <SEO title="Playing With Hexagons V2" />
      <h2>Hexagons v2</h2>
      <a href="https://liammyles.github.io/Playing-With-Polygons-Prototype/">
        View on Github Pages
      </a>{" "}
      or{" "}
      <a href="https://github.com/LiamMyles/Playing-With-Polygons-Prototype">
        Checkout Hexagons v2 Github repo
      </a>
      <ResponsiveIFrame src="https://liammyles.github.io/Playing-With-Polygons-Prototype/" />
    </StandardLayout>
  )
}

export default HexagonsV2
